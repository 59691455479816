<template>
	<v-container fluid>
		<v-container>
		<!--<v-timeline align-top :dense="$vuetify.breakpoint.smAndDown" dense>-->
			<v-timeline dense>
				<v-timeline-item v-for="(item, i) in items" :key="i" :color="item.color" :icon="item.icon" fill-dot>
					<v-card :color="item.color" dark>
						<v-card-title class="title">{{ item.label }}</v-card-title>
						<v-card-text class="white text--primary">
							<div class="incenter">
								<v-avatar class="profile" size="150" tile>
									<v-img contain :src="item.img"></v-img>
								</v-avatar>
							</div>
							<br>
							<div v-for="(dat, ind) in item.contact_data" :key="ind">
								<template v-if="dat.key == '' && dat.label == ''"><br><hr><br></template>
								<template v-else-if="dat.key == ''">{{ dat.label }}</template>
								<template v-else-if="dat.key == 'bold'"><b>{{ dat.label }}</b></template>
								<template v-else-if="dat.key == 'map'">
									<br>
									<div class="centered">
										<iframe frameborder="0" height="220" :src="dat.label" style="border:0" width="90%"></iframe>
									</div>
								</template>
								<template v-else>{{ dat.key + ' ' + dat.label}}</template>
							</div>
							<!-- <v-btn :color="item.color" class="mx-0" outlined>Button</v-btn> -->
						</v-card-text>
					</v-card>
				</v-timeline-item>
			</v-timeline>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
			items: [
				
				{
					label: 'Kontakty',
					img: '/pictures/pics/multi/contact/con2_sectro_rac.png',
					contact_data: [
						{ key: '', label: 'SECTRO s.r.o.'},
						{ key: '', label: 'Račianska 66'},
						{ key: '', label: '831 02 Bratislava'},
						{ key: '', label: ''},
						{ key: 'IČO:', label: '46507248'},
						{ key: 'IČ DPH:', label: 'SK2023512447'},
						{ key: '', label: 'ČSOB Bratislava-námestie SNP, Slovakia'},
						{ key: 'IBAN:', label: 'SK21 7500 0000 0040 1598 6296'},
						{ key: 'BIC:', label: 'CEKOSKBX'},
//						{ key: '', label: ''},
//						{ key: 'E-mail:', label: 'sectro@sectro.sk'},
//						{ key: 'Mobil:', label: '+421 915 575 155'},
//						{ key: 'Mobil:', label: '+421 948 811 700'},
            { key: '', label: ''},
            { key: 'bold', label: 'Obchodné oddelenie'},
						{ key: 'E-mail:', label: 'obchod@sectro.sk'},
						{ key: 'Mobil:', label: '+421 915 575 155'},
            { key: '', label: ''},
            { key: 'bold', label: 'Objednávky'},
						{ key: 'E-mail:', label: 'logistika@sectro.sk'},
						{ key: 'Mobil:', label: '+421 905 301 682'},
            { key: '', label: ''},
            { key: 'bold', label: 'Technická podpora'},
						{ key: 'E-mail:', label: 'podpora@sectro.sk'},
						{ key: 'Mobil:', label: '+421 917 591 660'},
            { key: '', label: ''},
            { key: 'bold', label: 'Ekonomické oddelenie'},
						{ key: 'E-mail:', label: 'ekonomicke@sectro.sk'},
						{ key: 'Mobil:', label: '+421 948 811 700'},
						{ key: 'map', label: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d665.1093006531376!2d17.129620679173208!3d48.178927209850066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c8940d13d3073%3A0xcf7434269de1e638!2sSectro%20s.%20r.%20o.!5e0!3m2!1ssk!2ssk!4v1726562733106!5m2!1ssk!2ssk'},
					],
					color: 'grey',
					icon: 'mdi-home'
				},
			]
		}),
	}
</script>

<style scoped>
.incenter {
	text-align: center;
}
</style>
